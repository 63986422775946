<template>
  <div class="position-fixed bottom-0 end-0 on-top">
    <div :id="'toast-'+index" class="toast mb-2 me-2" :class="message.type" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="10000" v-for="(message, index) in toasts" :key="message.id">
      <div class="toast-header">
        <strong class="me-auto">{{ message.header }}</strong>
        <small>{{ message.smallText }}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body" v-if="message.detailText">
        {{ message.detailText }}
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, onUpdated, ref, watch} from 'vue'
import {Toast} from 'bootstrap';
import {useMessagesStore} from "@/store/modules/messages/messages.js";

const messageStore = useMessagesStore();
const toasts = ref([]);

onBeforeMount(() => {
  toasts.value = messageStore.getToasts;
})

onMounted(() => {
  showToasts();
})

onUpdated(() => {
  showToasts();
})

const showToasts = () => {
  for (let i = 0; i < toasts.value.length; i++) {
    let id = "toast-" + i;
    const toastEL = document.getElementById(id);
    if (toastEL !== null) {
      toastEL.addEventListener("hidden.bs.toast", () => {
        messageStore.removeMessage(toasts.value[i]);
      });
      const toastToShow = new Toast(toastEL);
      toastToShow.show();
    }
  }
}

const getToasts = computed(() => {
  return messageStore.getToasts;
})

watch(getToasts, () => {
  toasts.value = messageStore.getToasts;
})
</script>

<style lang="scss" scoped>
.on-top {
  z-index: 7000;
}

.toast {
  font-family: var(--bs-body-font-family);
  color: #5C6972;

  &.info {
    border-color: var(--bs-info-border-subtle);

    > div.toast-header {
      border-color: var(--bs-info-border-subtle);
      background-color: var(--bs-info-bg-subtle);
    }
  }

  &.error {
    border-color: var(--bs-danger-border-subtle);

    > div.toast-header {
      border-color: var(--bs-danger-border-subtle);
      background-color: var(--bs-danger-bg-subtle);
    }
  }

  &.success {
    border-color: var(--bs-success-border-subtle);

    > div.toast-header {
      border-color: var(--bs-success-border-subtle);
      background-color: var(--bs-success-bg-subtle);
    }
  }
}


</style>
