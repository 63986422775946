<template>
  <association-layout :is-loading="false"
    :title="currentClubData.status === 200 ? $t('association.association') + ' ' + currentClubData.data?.Name : $t('association.notFound.title')" >
    <template #content v-if="currentClubData.status === 200">

      <div class="col-12">

    <DataTable :value="memberLSPAData"
               scrollable scroll-height="60vh"
               :sort-field="sortColumn" :sort-order="1" @sort="onSort"
               data-key="memberId"
               ref="dt"
               v-model:filters="filters" filter-display="row"
               :loading="isLoading"
               :paginator="memberLSPAData.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
               paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
               :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
               csv-separator=";"
               :export-filename="$t('breadcrumb.association.lspaControl')"
               :export-function="exportFunction"
               row-hover
               class="border-bottom">

      <template #empty>
        <div class="alert alert-info">{{ $t('association.lspa.notFound') }}</div>
      </template>
      <template #paginatorfirstpagelinkicon>
        <i class="bi bi-arrow-bar-left"/>
      </template>
      <template #paginatorprevpagelinkicon>
        <i class="bi bi-arrow-left"/>
      </template>
      <template #paginatornextpagelinkicon>
        <i class="bi bi-arrow-right"/>
      </template>
      <template #paginatorlastpagelinkicon>
        <i class="bi bi-arrow-bar-right"/>
      </template>

      <Column v-for="col in columns" :key="col.field" :field="col.field"
              :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
              :header="col.header"
              :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
              :exportable="col.exportable"
              :hidden="!col.visible">
        <template #body="{data}">
          {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
        </template>
        <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
          <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
        </template>
      </Column>
    </DataTable>
        <div class="row w-100 flex-center mb-3 mt-3">
          <Button title="Export" label="Export" @click="exportCSV($event)" class="datatable-export me-1 width-auto" severity="secondary" outlined>
            <i class="bi bi-filetype-csv"></i>
          </Button>
        </div>
      </div>
      <LoaderOverlay v-if="isLoading"/>
    </template>
      <template #content v-else>
        <NoClubFoundAlert/>
      </template>
  </association-layout>

</template>

<script setup>
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import {useClubStore} from "@/store/modules/club/club";
import {computed, onMounted, ref} from "vue";
import ApiClient from "@/api/ApiClient";
import moment from "moment";
import {FilterMatchMode} from "@primevue/core/api";
import {useI18n} from "vue-i18n";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import Button from "primevue/button";


const clubStore = useClubStore();
const currentClubData = computed(() => clubStore.getClubData);
const {t} = useI18n()

const memberLSPAData = ref({});
const isLoading = ref(true);

const dt = ref();

const exportCSV = () => {
  dt.value.exportCSV();
}

const sortColumn = ref('issuingClubName');
const onSort = (event) => {
  sortColumn.value = event.sortField;
}


const columns = [
  {field: 'issuingClubName', header: t('association.lspa.issuingClub'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'issuingClubNumber', header: t('association.lspa.issuingClubNumber'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'sportId', header: t('association.lspa.sport'), filterable: false, exportable: true, sortable: true, visible: true, type: 'Sport'},
  {field: 'latestYearValid', header: t('association.lspa.validYear'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'salutation', header: t('club.listMember.salutation'), filterable: false, exportable: true, visible: true, type: 'Salutation'},
  {field: 'languageId', header: t('club.member.language'), filterable: false, exportable: true, visible: true, type: 'Language'},
  {field: 'lastName', header: t('club.member.surname'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'firstName', header: t('club.member.firstName'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'birthday', header: t('club.member.birthday'), filterable: false, exportable: true, visible: true, type: 'Date'},
  {field: 'street', header: t('address.street'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'postalCode', header: t('address.plz'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'city', header: t('address.city'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'country', header: t('address.country'), filterable: false, exportable: true, visible: true, type: 'String'},

];
const filters = ref({
  issuingClubName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  issuingClubNumber: {value: null, matchMode: FilterMatchMode.CONTAINS}
})

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => (a && a[b] !== undefined ? a[b] : null), obj);
};

const exportFunction = (entry) => {
  switch (entry.field) {
    case 'salutation':
      return formatEntry('Salutation', entry.data);
    case 'languageId':
      return formatEntry('Language', entry.data);
    case 'birthday':
      return formatDate(entry.data);
    case 'sportId':
      return formatEntry('Sport', entry.data);
    default:
      return String(entry.data)
  }
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      return t('club.member.salutation' + value);
    case 'Language':
      return t('languages.' + value);
    case 'Sport':
      return t('sport.' + value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

onMounted(async () => {
  let response = await ApiClient.getRequest("/api/lspa/association?associationId=" + currentClubData.value.data.No);

  if(response && response.status === 200) {
    memberLSPAData.value = response.data;
  } else {
    console.error(response);
  }
  isLoading.value = false;
})


</script>
