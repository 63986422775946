import moment from "moment/moment";

export const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return value !== undefined && value !== null && (value.trim() === '' || value.trim().length <= maxLength);
}

export const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return !isEmpty(value) && value.trim().length < maxLength;
}

export const isRequired = (value) => {
  return !isEmpty(value) && value.trim().length > 0;
}

export const isSelected = (value) => {
  return !isEmpty(value) && value.trim().length > 0;
}

export const isEmpty = (value) => {
  return !value || value.trim() === '';
}

export const dateValid = (value) => {
  const dateValue = moment(String(value));
  return dateValue.isValid() && dateValue.isAfter('1900-01-01');
};

export const dateAfter = (startDate, endDate) => {
  const startValue = moment(String(startDate));
  const endValue = moment(String(endDate));
  return startValue.isAfter(endValue);
};

export const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
};
