import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const ASSOCIATION_BASE_PATH = "/api/association";

export const useAssociationStore = defineStore("association", {
  state: () => {
    return {
      clubData: null,
      allClubs: null,
      clubMembers: null,
      listFunctionMembers: [],
      clubFunctions: null,
    };
  },

  actions: {
    async loadAllClubs(associationId) {
      const response = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/list-clubs?associationId=' + associationId);
      if (response?.status < 500 && response?.data) {
        this.allClubs = response.data;
      } else {
        this.allClubs = null;
      }
    },

    async loadClubListMembers(associationId, clubId, overwrite, activeMembersOnly, clubMembershipsOnly, language) {
      this.clubMembers = [];
      const payload = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/list-club-members?associationId=' + associationId + '&clubId=' + clubId +
        "&overwrite=" + overwrite +
        "&activeMembersOnly=" + activeMembersOnly +
        "&clubMembershipsOnly=" + clubMembershipsOnly +
        "&language=" + language);

      if (payload?.data) {
        this.clubMembers = payload.data.filter((m, i, s) => i === s.findIndex(m2 => (m2.memberId === m.memberId)));
      } else {
        this.clubMembers = [];
      }
    },

    async loadClubData(associationId, clubId) {
      const clubData = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/clubById?associationId=' + associationId + '&clubId=' + clubId);
      if (clubData?.status < 500) {
        this.clubData = clubData;
      } else {
        this.clubData = null;
      }
    },

    async loadListFunctionMembers(associationId) {
      const response = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/list-function-members?associationId=' + associationId);
      if (response?.status === 200) {
        this.listFunctionMembers = response.data;
      } else {
        this.listFunctionMembers = [];
      }
    },

    async loadFunctions(associationId) {
      const clubFunctions = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/list-club-functions?associationId=' + associationId);
      if (clubFunctions?.status < 500) {
        this.clubFunctions = clubFunctions;
      } else {
        this.clubFunctions = null;
      }
    },

    async saveAddressRelation(associationId, payload) {
      if (payload?.Entry_No) {
        return await ApiClient.putRequest(ASSOCIATION_BASE_PATH + '/club-address-relation?associationId=' + associationId, payload);
      } else if (payload) {
        return await ApiClient.postRequestWithAuthorization(ASSOCIATION_BASE_PATH + '/club-address-relation?associationId=' + associationId, payload);
      }
      return null;
    },

    async deleteClubAddressRelation(associationId, entryNo) {
      return await ApiClient.deleteRequest(ASSOCIATION_BASE_PATH + '/club-address-relation?associationId=' + associationId + '&entryNo=' + entryNo)
    },

    setCurrentClubId(clubId) {
      this.clubData.currentClubId = clubId;
    },
    setCurrentClubName(clubName) {
      this.clubData.currentClubName = clubName;
    },
  },

  getters: {
    getAllClubs: (state) => {
      if (state.allClubs) {
        return state.allClubs.toSorted((a, b) => a.No.localeCompare(b.No));
      }
      return null;
    },

    getClubMembers: (state) => {
      if (state.clubMembers) {
        return state.clubMembers;
      }
      return [];
    },

    getCurrentClubData: (state) => {
      if (state.clubData) return state.clubData;
      return null;
    },
    getCurrentClubId: (state) => {
      return state.ClubData.currentClubId;
    },
    getCurrentClubName: (state) => {
      return state.clubData.currentClubName;
    },
    getListFunctionMembers: (state) => {
      return state.listFunctionMembers;
    },
    getClubFunctions: (state) => {
      return state.clubFunctions;
    },

  },

  persist: {
    storage: sessionStorage,
  }
});
