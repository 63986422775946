<template>
  <association-layout :title="clubData.status === 200 ? $t('association.functionsAndActivities.title') + ' ' + clubData.data.Name : $t('association.notFound.title')" :is-loading="false">

    <template #content v-if="clubData.status === 200">
      <AssociationAddressRelationTable :club-id="clubData.data.No"/>

      <!-- Tätigkeiten -->
      <div v-if="clubData.data.No">
        <ClubActivityTable :club-id="clubData.data.No"></ClubActivityTable>
        <MemberActivityTable :club-id="clubData.data.No"></MemberActivityTable>
      </div>

    </template>

    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </association-layout>


  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import AssociationLayout from "@/components/association/AssociationLayout.vue";

import {computed} from "vue";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";

import MemberActivityTable from "@/components/activity/MemberActivityTable.vue";
import ClubActivityTable from "@/components/activity/ClubActivityTable.vue";
import AssociationAddressRelationTable from "@/components/activity/AssociationAddressRelationTable.vue";
import {useClubStore} from "@/store/modules/club/club";

const clubStore = useClubStore();

const clubData = computed(() => {
  return clubStore.getClubData;
});

clubStore.loadClubMembers(clubData.value.data.No, false);
clubStore.loadFunctions(clubData.value.data.No);
</script>

