<template>
  <club-layout :title="t('breadcrumb.club.guardians')" :is-loading="isLoading">
    <template #content>
      <h6 class="mb-4">{{ t('club.guardians.list.title') }}</h6>
      <DataTable :value="guardians"
                 scrollable scroll-height="60vh"
                 :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                 data-key="entryNo"
                 v-model:filters="filters" filter-display="row"
                 ref="dt" csv-separator=";" :export-filename="t('club.guardians.list.title').replaceAll(' ', '_')" :export-function="exportFunction"
                 row-hover
                 :paginator="guardians.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
                 class="border-bottom"
                 :loading="guardiansLoading">
        <template #empty>
          <div class="alert alert-info">{{ t('club.guardians.list.notFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <ColumnGroup type="header">
          <Row>
            <Column/>
            <Column :header="t('club.guardians.child')" :colspan="5"/>
            <Column :header="t('club.guardians.guardian')" :colspan="13"/>
          </Row>
          <Row>
            <Column/>
            <Column v-for="col in columns" :header="col.header" :key="col.field" :field="col.field"
                    :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :class="col.border ? 'border-start' : null"
                    :hidden="!col.visible"/>
          </Row>
        </ColumnGroup>

        <Column frozen>
          <template #body="{data}">
            <div class="btn-group btn-group-sm">
              <Button :title="t('edit', {object: t('club.guardians.guardian')})" :label="null"
                      :pt="{label: {style: 'display: none'}}"
                      unstyled class="btn btn-outline-secondary"
                      icon="bi bi-pencil"
                      :loading="modalLoading" loading-icon="spinner-border spinner-border-sm"
                      @click="loadGuardianModal(data)"/>
              <Button :title="t('delete')" :label="null"
                      :pt="{label: {style: 'display: none'}}"
                      unstyled class="btn btn-outline-primary"
                      icon="bi bi-trash"
                      @click="confirmDelete($event, data)"/>
            </div>
          </template>
        </Column>

        <Column v-for="col in columns" :key="col.field" :field="col.field"
                :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header="col.header"
                :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                :exportable="col.exportable"
                :class="col.border ? 'border-start' : null"
                :style="(col.field === 'stvReducedMember.squadTextsDe' || col.field === 'stvReducedMember.squadTextsFr') ? 'max-width: 20rem; overflow: hidden; -ms-text-overflow: ellipsis; text-overflow: ellipsis;' : null"
                :hidden="!col.visible">
          <template #body="{data}">
            <span :title="(col.field === 'stvReducedMember.squadTextsDe' || col.field === 'stvReducedMember.squadTextsFr') ? formatEntry(col.type, getDescendantProp(data, col.field)) : null">
            {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
            <Select v-model="filterModel.value"
                    :options="possibleSalutations" option-value="code" option-label="name"
                    @change="filterCallback()"
                    class="select-filter"
                    v-if="col.type === 'Salutation'"/>

            <InputText v-model="filterModel.value"
                       type="text"
                       @input="filterCallback()"
                       :placeholder="t('section.clubMembers.filter', {filter: col.header})"
                       unstyled class="form-control input-filter"
                       v-else/>
          </template>
        </Column>

      </DataTable>

      <div class="row w-100 flex-center mb-3 mt-3">
        <Button :title="t('export')" :label="null"
                @click="exportCSV($event)"
                unstyled class="btn btn-outline-primary datatable-export width-auto me-1"
                icon="bi bi-filetype-csv"
                :pt="{label: {style: 'display: none'}}"/>
        <Button :title="t('club.guardians.addGuardian')" :label="t('club.guardians.addGuardian')"
                unstyled class="btn btn-outline-secondary width-auto me-1"
                icon="bi bi-person-add me-1" loading-icon="spinner-border spinner-border-sm me-1"
                :loading="modalLoading"
                @click="loadGuardianModal(null)"/>
      </div>
    </template>

    <Dialog v-model:visible="visibleGuardianModal" modal
            :header="guardianData.Entry_No ? t('edit', {object: t('club.guardians.guardian')}) : t('create', {object: t('club.guardians.guardian')})"
            :style="{width: '35rem'}"
            pt:footer:class="border-top"
            pt:header:class="border-bottom"
            @hide="resetGuardianData">
      <div class="form-row mb-3 pt-3" v-focustrap>
        <h4 class="mb-3">{{ t('club.guardians.child') }}</h4>
        <label for="memberId" class="form-label required">{{ t('institutes.functions.selectMember') }}</label>
        <Select id="memberId" v-model="guardianData.Contact_No"
                :options="clubMemberList" option-value="memberId" :option-label="getMemberName"
                autofocus checkmark filter
                class="w-100"
                @update:model-value="validateGuardianData"
                v-tooltip.bottom="{value: t('club.guardians.ttChild')}"
                required :tabindex="1"/>
        <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="guardianTouched.Contact_No && !guardianValid.Contact_No">{{ t('validation.selectMember') }}</PrimeMessage>
      </div>

      <div class="form-row mb-3 pt-3 border-top">
        <h4>{{ t('club.guardians.guardian') }}</h4>
      </div>
      <div class="form-row mb-3">
        <label for="salutation" class="form-label">{{ t('club.member.salutation') }}</label>
        <Select id="salutation" v-model="guardianData.Salutation_Code" :placeholder="t('club.member.salutation')" :options="possibleGenders" option-label="name" option-value="code" class="w-100" :tabindex="2" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="firstName" class="form-label required">{{ t('club.member.firstName') }}</label>
        <InputText id="firstName" v-model="guardianData.First_Name" :placeholder="t('club.member.firstName')" @update:model-value="validateGuardianData" class="w-100" required :tabindex="3" @keyup.enter="submitGuardianData"/>
        <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="guardianTouched.First_Name && !guardianValid.First_Name">{{ t('validation.emptyOrExceeded', {field: t('club.member.firstName'), maxlength: 30}) }}</PrimeMessage>
      </div>
      <div class="form-row mb-3">
        <label for="surname" class="form-label required">{{ t('club.member.surname') }}</label>
        <InputText id="surname" v-model="guardianData.Surname" :placeholder="t('club.member.surname')" @update:model-value="validateGuardianData" class="w-100" required :tabindex="4" @keyup.enter="submitGuardianData"/>
        <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="guardianTouched.Surname && !guardianValid.Surname">{{ t('validation.emptyOrExceeded', {field: t('club.member.surname'), maxlength: 50}) }}</PrimeMessage>
      </div>
      <div class="form-row mb-3">
        <label for="release" class="form-label">{{ t('club.listMember.releaseMySTV') }}</label>
        <InputMask id="release" v-model="guardianData.ReleasemySTVFSG" mask="999?9999" placeholder="0000000" :aria-label="t('club.member.release')" class="w-100" :tabindex="5" v-tooltip.bottom="{value: t('club.guardians.ttReleaseMyStv')}" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="address" class="form-label">{{ t('club.member.address') }}</label>
        <InputText id="address" v-model="guardianData.Address" :placeholder="t('club.member.address')" class="w-100" :tabindex="6" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="address2" class="form-label">{{ t('club.member.addressAddition') }}</label>
        <InputText id="address2" v-model="guardianData.Address_2" :placeholder="t('club.member.addressAddition')" class="w-100" :tabindex="7" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="postCode" class="form-label">{{ t('club.member.postCode') }}</label>
        <InputText id="postCode" v-model="guardianData.Post_Code" :placeholder="t('club.member.postCode')" class="w-100" :tabindex="8" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="city" class="form-label">{{ t('club.member.city') }}</label>
        <InputText id="city" v-model="guardianData.City" :placeholder="t('club.member.city')" class="w-100" :tabindex="9" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="county" class="form-label">{{ t('club.member.county') }}</label>
        <CountySelect id="county" v-model="guardianData.County" class="w-100" :tabindex="10" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="countryRegionCode" class="form-label">{{ t('club.member.country') }}</label>
        <CountrySelect id="countryRegionCode" v-model="guardianData.Country_Region_Code" class="w-100" :tabindex="11" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="phoneNoPrivate" class="form-label">{{ t('club.member.phonePrivate') }}</label>
        <InputText id="phoneNoPrivate" v-model="guardianData.Phone_No_private" :placeholder="t('club.member.phonePrivate')" class="w-100" :tabindex="12" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="phoneNo" class="form-label">{{ t('club.listMember.phoneBusiness') }}</label>
        <InputText id="phoneNo" v-model="guardianData.Phone_No" :placeholder="t('club.listMember.phoneBusiness')" class="w-100" :tabindex="13" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="mobilePhoneNo" class="form-label">{{ t('club.listMember.phoneMobile') }}</label>
        <InputText id="mobilePhoneNo" v-model="guardianData.Mobile_Phone_No" :placeholder="t('club.listMember.phoneMobile')" class="w-100" :tabindex="14" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="email2" class="form-label">{{ t('club.member.emailPrivate') }}</label>
        <InputText id="email2" v-model="guardianData.E_Mail_2" :placeholder="t('club.member.emailPrivate')" class="w-100" :tabindex="15" @keyup.enter="submitGuardianData"/>
      </div>
      <div class="form-row mb-3">
        <label for="email" class="form-label">{{ t('club.member.emailAlternative') }}</label>
        <InputText id="email" v-model="guardianData.E_Mail" :placeholder="t('club.member.emailAlternative')" class="w-100" :tabindex="16" @keyup.enter="submitGuardianData"/>
      </div>

      <template #footer>
        <div class="w-100 d-flex justify-content-between pt-3">
          <Button :title="t('save')" :label="t('save')" unstyled class="btn btn-outline-primary" :disabled="!allTrue(guardianValid) || isSubmitted" @click="submitGuardianData"/>
          <Button :title="t('cancel')" :label="t('cancel')" unstyled class="btn btn-outline-secondary" @click="visibleGuardianModal = false"/>
        </div>
      </template>
    </Dialog>
  </club-layout>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import ClubLayout from "@/components/club/ClubLayout.vue";
import {useI18n} from "vue-i18n";
import {computed, onMounted, ref, watch} from "vue";
import {useClubStore} from "@/store/modules/club/club";
import {useRoute} from "vue-router";
import moment from "moment/moment";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";

import {ColumnGroup, Row} from "primevue";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Select from "primevue/select";
import Button from "primevue/button";
import PrimeMessage from "primevue/message";
import {FilterMatchMode} from "@primevue/core/api";
import {useConfirm} from "primevue/useconfirm";
import CountrySelect from "@/components/ui/CountrySelect.vue";
import CountySelect from "@/components/ui/CountySelect.vue";

const {t} = useI18n();
const route = useRoute();
const i18nLocale = useI18n().locale.value;

const isLoading = ref(false);
const guardiansLoading = ref(false);
const modalLoading = ref(false);
const clubStore = useClubStore();

const guardians = computed(() => {
  return clubStore.getClubGuardians;
});

const columns = [
  {field: 'stvReducedMember.memberId', header: t('club.member.stvNumber'), sortable: true, filterable: true, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'stvReducedMember.salutation', header: t('club.member.salutation'), sortable: true, filterable: true, exportable: true, visible: false, border: false, type: 'Salutation'},
  {field: 'stvReducedMember.surname', header: t('club.member.surname'), sortable: true, filterable: true, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'stvReducedMember.firstName', header: t('club.member.firstName'), sortable: true, filterable: true, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'stvReducedMember.squadTextsDe', header: t('club.listMember.squads', {count: 2}), sortable: true, filterable: false, exportable: i18nLocale === 'de', visible: false, border: false, type: 'Array'},
  {field: 'stvReducedMember.squadTextsFr', header: t('club.listMember.squads', {count: 2}), sortable: true, filterable: false, exportable: i18nLocale === 'fr', visible: false, border: false, type: 'Array'},
  {field: 'salutation', header: t('club.member.salutation'), sortable: true, filterable: false, exportable: true, visible: true, border: true, type: 'Salutation'},
  {field: 'language', header: t('club.member.language'), sortable: true, filterable: false, exportable: true, visible: false, border: false, type: 'Language'},
  {field: 'surname', header: t('club.member.surname'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'firstName', header: t('club.member.firstName'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'address', header: t('club.member.address'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'address2', header: t('club.member.addressAddition'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'postCode', header: t('club.member.postCode'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'city', header: t('club.member.city'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'county', header: t('club.member.county'), sortable: true, filterable: false, exportable: true, visible: false, border: false, type: 'String'},
  {field: 'countryRegionCode', header: t('club.member.country'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'email2', header: t('club.member.emailPrivate'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'email', header: t('club.member.emailAlternative'), sortable: true, filterable: false, exportable: true, visible: false, border: false, type: 'String'},
  {field: 'phoneNoPrivate', header: t('club.member.phonePrivate'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'phoneNo', header: t('club.listMember.phoneBusiness'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
  {field: 'mobilePhoneNo', header: t('club.listMember.phoneMobile'), sortable: true, filterable: false, exportable: true, visible: true, border: false, type: 'String'},
];
const sortColumn = ref('memberId');
const dt = ref();
const onSort = (event) => {
  sortColumn.value = event.sortField;
};
const filters = ref({
  'stvReducedMember.memberId': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvReducedMember.salutation': {value: null, matchMode: FilterMatchMode.EQUALS},
  'stvReducedMember.surname': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvReducedMember.firstName': {value: null, matchMode: FilterMatchMode.CONTAINS},
});

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => a[b], obj);
};

// Neue Erziehungsberechtigte erstellen
const showSuccess = ref(false);
const showFailure = ref(false);
const messageStore = useMessagesStore();
const possibleSalutations = ref([
  {name: t('club.member.salutationMS'), code: 'MS'},
  {name: t('club.member.salutationMR'), code: 'MR'},
]);
const possibleGenders = ref([
  {name: t('club.member.salutationMS'), code: 'W'},
  {name: t('club.member.salutationMR'), code: 'M'},
]);

const loadGuardianModal = async (data) => {
  modalLoading.value = true
  clubStore.loadClubMembers(route.query.clubId, true).then(() => {
    if (data) {
      setGuardianData(data);
    }
    modalLoading.value = false;
    visibleGuardianModal.value = true;
  });
};

const setGuardianData = (data) => {
  guardianData.value.Entry_No = data.entryNo;
  guardianData.value.Contact_No = data.stvReducedMember.memberId;
  guardianData.value.Salutation_Code = possibleGenders.value.find(s => s.code === data.salutation.substr(0, 1)).code;   //data.salutation;
  guardianData.value.First_Name = data.firstName;
  guardianData.value.Surname = data.surname;
  guardianData.value.ReleasemySTVFSG = data.releaseMyStvFSG;
  guardianData.value.Address = data.address;
  guardianData.value.Address_2 = data.address2;
  guardianData.value.Post_Code = data.postCode;
  guardianData.value.City = data.city;
  guardianData.value.County = data.county;
  guardianData.value.Country_Region_Code = data.countryRegionCode;
  guardianData.value.Language_Code = data.language;
  guardianData.value.Phone_No_private = data.phoneNoPrivate;
  guardianData.value.Phone_No = data.phoneNo;
  guardianData.value.Mobile_Phone_No = data.mobilePhoneNo;
  guardianData.value.E_Mail_2 = data.email2;
  guardianData.value.E_Mail = data.email;
};

const visibleGuardianModal = ref(false);
const clubMemberList = computed(() => {
  return clubStore.getClubMembers;
});
const getMemberName = (member) => {
  return member.firstName + ' ' + member.surname;
};
const guardianData = ref({
  Contact_No: '',
  Salutation_Code: '',
  First_Name: '',
  Surname: '',
  Address: '',
  Address_2: '',
  Post_Code: '',
  City: '',
  County: '',
  Country_Region_Code: '',
  Language_Code: '',
  Phone_No_private: '',
  Phone_No: '',
  Mobile_Phone_No: '',
  E_Mail_2: '',
  E_Mail: '',
  ReleasemySTVFSG: '',
});
const guardianValid = ref({
  Contact_No: false,
  First_Name: false,
  Surname: false,
});
const guardianTouched = ref({});

const validateGuardianData = () => {
  guardianValid.value.Contact_No = !!guardianData.value.Contact_No;
  guardianValid.value.First_Name = !!guardianData.value.First_Name;
  guardianValid.value.Surname = !!guardianData.value.Surname;
};

watch(guardianData.value, (value) => {
  for (const key in value) {
    if (value[key]) guardianTouched.value[key] = true;
  }
  validateGuardianData();
}, {
  deep: true,
});

const allTrue = (obj) => {
  return Object.values(obj).every(value => value);
};

const resetGuardianData = () => {
  guardianData.value = {};
  guardianTouched.value = {};
  guardianValid.value = {};
};

const isSubmitted = ref(false);
const submitGuardianData = () => {
  isSubmitted.value = true;
  clubStore.saveGuardian(guardianData.value, route.query.clubId).then(async response => {
    if (response?.status < 400) {
      showSuccess.value = true;
      guardiansLoading.value = true;
      clubStore.loadClubGuardians(route.query.clubId, false).then(() => {
        guardiansLoading.value = false;
      });
      visibleGuardianModal.value = false;
      messageStore.addInfo(new Message('info', true, false, t('save', {object: t('club.guardians.addGuardian')}), '', t('club.guardians.save.success'), false, 'COMPONENT'));
    } else {
      showFailure.value = true;
      let combinedMessage = '';
      if (response.data.errors) {
        response.data.errors.forEach(e => {
          combinedMessage += t(e) + '\r\n';
        });
      } else if (response.data.key) {
        combinedMessage = t(response.data.key, response.data.params);
      } else {
        combinedMessage = t(response.data);
      }
      messageStore.addError(new Message('error', true, false, t('save', {object: t('club.guardians.addGuardian')}), '', combinedMessage, false, 'COMPONENT'));
    }
  }).catch(error => {
    showFailure.value = true;
    messageStore.addError(new Message('error', true, false, t('save', {object: t('club.guardians.addGuardian')}), '', error, false, 'COMPONENT'));
  }).finally(() => {
    isSubmitted.value = false;
  });
};

// Erziehungsberechtigte löschen
const confirm = useConfirm();

const confirmDelete = (event, guardian) => {
  confirm.require({
    target: event.currentTarget,
    header: t('club.guardians.delete.title'),
    message: t('club.guardians.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      deleteGuardian(route.query.clubId, guardian.entryNo);
    },
  });
};

const deleteGuardian = async (clubId, entryNo) => {
  clubStore.deleteGuardian(clubId, entryNo).then(response => {
    if (response.status >= 200 && response.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('club.guardians.delete.title'), '', t('club.guardians.delete.success'), false, 'COMPONENT'));
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.guardians.delete.title'), '', t(response.data), false, 'COMPONENT'));
    }
  }).catch(error => {
    showFailure.value = true;
    messageStore.addError(new Message('error', true, false, t('club.guardians.delete.title'), '', error, false, 'COMPONENT'));
  }).finally(() => {
    guardiansLoading.value = true;
    clubStore.loadClubGuardians(route.query.clubId, false).then(() => {
      guardiansLoading.value = false;
    });
  });
};

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return value.join(', ');
    case 'Salutation':
      if (value === 'UNDEFINED') {
        return '';
      }
      return t('club.listMember.salutation' + value);
    case 'Language':
      return t('languages.' + value.toLowerCase());
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}

const exportFunction = (entry) => {
  switch (entry.field) {
    case 'salutation':
    case 'stvReducedMember.salutation':
      return formatEntry('Salutation', entry.data);
    case 'language':
      return formatEntry('Language', entry.data);
    case 'stvReducedMember.squadTextsDe':
    case 'stvReducedMember.squadTextsFr':
      return formatEntry('Array', entry.data);
    default:
      return String(entry.data);
  }
};

const exportCSV = () => {
  dt.value.exportCSV();
};

onMounted(async () => {
  guardiansLoading.value = true;
  await clubStore.loadClubGuardians(route.query.clubId, true).then(() => {
    guardiansLoading.value = false;
  });
});
</script>
