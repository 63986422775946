<template>
  <club-layout :title="vereinName" :is-loading="isLoading">
    <template #content>
      <div class="row">
        <div class="col-12 mb-3">
          <h3>{{ $t('breadcrumb.club.membercards') }}</h3>

          <DataTable :value="clubMembers"
                     :sortField="sortColumn" :sortOrder="1" @sort="onSort"
                     v-model:selection="selection"
                     dataKey="memberId"
                     v-model:filters="filters" filterDisplay="row"
                     :loading="isLoading"
                     ref="dt"
                     paginator :rows="50" :rowsPerPageOptions="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
                     csvSeparator=";" :export-filename="$t('section.clubMembers.exportFilename') + ' ' + vereinName">

            <template #empty>{{ $t('section.clubMembers.noMembersFound') }}</template>
            <template #loading> Loading customers data. Please wait.</template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column selectionMode="multiple" class="dt-col-2"></Column>

            <Column field="surname" filterField="surname" :header="$t('personalData.person.surname')" sortField="surname" sortable class="dt-col-12" :headerClass="(sortColumn === 'surname' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" :exportable="true">
              <template #body="{ data }">
                <router-link :to="{name: 'singleMember', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link">
                  {{ formatEntry('String', data.surname) }}
                </router-link>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.surname')})" class="form-control input-filter"/>
              </template>
            </Column>

            <Column field="firstName" filterField="firstName" :header="$t('personalData.person.firstName')" sortField="firstName" sortable class="dt-col-12" :headerClass="(sortColumn === 'firstName' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" :exportable="true">
              <template #body="{ data }">
                <router-link :to="{name: 'singleMember', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link">
                  {{ formatEntry('String', data.firstName) }}
                </router-link>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.firstName')})" class="form-control input-filter"/>
              </template>
            </Column>

            <Column field="birthday" filterField="birthday" :header="$t('personalData.person.birthday')" sortField="birthday" sortable class="dt-col-8" :headerClass="(sortColumn === 'birthday' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" :exportable="true">
              <template #body="{ data }">
                <router-link :to="{name: 'singleMember', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link">
                  {{ formatEntry('Date', data.birthday) }}
                </router-link>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.birthday')})" class="form-control input-filter"/>
              </template>
            </Column>

            <Column field="squadTextsDe" filterField="squadTextsDe" :header="$t('personalData.person.squads')" sortField="squadTextsDe" sortable class="dt-col-10"
                    :headerClass="(sortColumn === 'squadTextsDe' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link"
                    :showFilterMenu="false"
                    :exportable="true"
                    v-if="i18nLocale === 'de'">
              <template #body="slotProps">
            <span :key="squadText" v-for="squadText in slotProps.data.squadTextsDe">
              <router-link :to="{name: 'singleMember', query: {memberId: slotProps.data.memberId, clubId: currentClubId}}" class="table-link">
                {{ formatEntry('String', squadText) }}
              </router-link>
            </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.squads')})" class="form-control input-filter"/>
              </template>
            </Column>

            <Column field="squadTextsFr" filterField="squadTextsFr" :header="$t('personalData.person.squads')" sortField="squadTextsFr" sortable class="dt-col-10"
                    :headerClass="(sortColumn === 'squadTextsFr' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link"
                    :showFilterMenu="false"
                    :exportable="true"
                    v-else>
              <template #body="slotProps">
            <span :key="squadText" v-for="squadText in slotProps.data.squadTextsFr">
              <router-link :to="{name: 'singleMember', query: {memberId: slotProps.data.memberId, clubId: currentClubId}}" class="table-link">
                {{ formatEntry('String', squadText) }}
              </router-link>
            </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('personalData.person.birthday')})" class="form-control input-filter"/>
              </template>
            </Column>

            <Column field="onboardingStatus" filterField="onboardingStatus" :header="$t('section.clubMembers.status.title')" sortField="onboardingStatus" sortable class="dt-col-6"
                    :headerClass="(sortColumn === 'onboardingStatus' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link"
                    :exportable="true">
              <template #body="{ data }">
                <a class="table-link">
                  <i class="bi" :class="[getStatusIcon(data.onboardingStatus.toLowerCase()), 'text-' + data.onboardingStatus.toLowerCase()]"
                     data-bs-toggle="tooltip" :data-bs-original-title="$t('section.clubMembers.status.' + data.onboardingStatus)"
                     :aria-label="$t('section.clubMembers.status.' + data.onboardingStatus)" :title="$t('section.clubMembers.status.' + data.onboardingStatus)"></i>
                </a>
              </template>
            </Column>
          </DataTable>
          <div class="w-100 flex-center">
            <div>
              <Button title="Export" label="Export" @click="exportCSV($event)" class="btn btn-outline-secondary datatable-export me-1" severity="secondary" outlined>
                <i class="bi bi-filetype-csv"></i>
              </Button>
            </div>
            <div class="btn-group" v-if="selection.length > 0">
              <button class="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      id="printDropdownToggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      :class="{'disabled': isSelectable && selection.length < 1}"
                      :disabled="isSelectable && selection.length < 1">
                {{ $t('print') }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="printDropdownToggle">
                <li><a href="#" class="dropdown-item" @click.prevent="printSingleCards">{{ $t('section.clubMembers.printSingleCard') }}</a></li>
                <li><a href="#" class="dropdown-item" @click.prevent="printCards">{{ $t('section.section.clubMembers.printCollection') }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <FaqPanel class="m-0 border-0"/>
        </div>
      </div>
    </template>

  </club-layout>

</template>

<script setup>

import {computed, onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {Tooltip} from "bootstrap";
import {openPrintWindow, openSingleCardsPrintWindow} from '@/helpers/print-multiple-cards';
import {FilterMatchMode} from "@primevue/core/api"
import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import {useI18n} from "vue-i18n";
import FaqPanel from "@/components/ui/FaqPanel.vue";
import Button from 'primevue/button';

import moment from "moment/moment";
import ClubLayout from "@/components/club/ClubLayout.vue";

const dt = ref();
const route = useRoute()
const clubStore = useClubStore();
const memberStore = useMemberStore();

const filters = ref({
  surname: {value: null, matchMode: FilterMatchMode.CONTAINS},
  firstName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  birthday: {value: null, matchMode: FilterMatchMode.CONTAINS},
  squadTextsDe: {value: null, matchMode: FilterMatchMode.CONTAINS},
  squadTextsFr: {value: null, matchMode: FilterMatchMode.CONTAINS},
  onboardingStatus: {value: null, matchMode: FilterMatchMode.CONTAINS}
});
const isSelectable = ref(false);
const isLoading = ref(false);
const sortColumn = ref('surname');
const selection = ref([]);
const i18nLocale = useI18n().locale.value;

const vereinName = computed(() => {
  let club = null;
  if (memberData.value.functions) {
    club = memberData.value.functions.find(f => {
      return !!f && f.organId === currentClubId.value;
    });
  }
  return club != null && club.organ !== null ? club.organ : '';
})

const verbandName = computed(() => {
  let membership = null;
  if (memberData.value.memberships) {
    membership = memberData.value.memberships.find(m => {
      return !!m.verein && m.verein.code === currentClubId.value
    });
  }
  return membership != null && membership.verband !== null ? membership.verband.description : '';
})

const memberData = computed(() => {
  return memberStore.getMemberData;
})

const clubMembers = computed(() => {
  return clubStore.getClubMembers;
})

const currentClubId = computed(() => {
  return clubStore.getCurrentClubId;
})

const sortSquads = (squads) => {
  return squads.sort((a, b) => a.description.localeCompare(b.description));
}

const getStatusIcon = (memberStatus) => {
  switch (memberStatus) {
    case 'initiated':
    case 'offboard':
    case 'pending':
    case 'reminded':
      return 'bi-circle-fill';
    case 'email_missing':
    case 'email_duplicate':
      return 'bi-exclamation-triangle-fill';
    case 'quarantine':
      return 'bi-wrench-adjustable-circle-fill';
    default:
      return 'bi-circle-fill';
  }
}

const onSort = (event) => {
  sortColumn.value = event.sortField;

  if (event.sortField === 'squads') {
    clubMembers.value.sort((a, b) => {
      const descriptionA = formatEntry('Array', sortSquads(a.squads)).toLowerCase();
      const descriptionB = formatEntry('Array', sortSquads(b.squads)).toLowerCase();

      if (event.sortOrder === 1) {
        let result = descriptionA.localeCompare(descriptionB);
        if (result == 0) {
          result = a.surname.localeCompare(b.surname);
          return result != null ? result : a.firstName.localeCompare(b.firstName);
        }
        return result;
      }

      let result = descriptionB.localeCompare(descriptionA);
      if (result == 0) {
        result = b.surname.localeCompare(a.surname);
        return result != null ? result : b.firstName.localeCompare(a.firstName);
      }
      return result;
    });
  }
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return [ ...new Set(Array.prototype.map.call(value, s => s.description)) ].join(', ');
    default:
      return value;
  }
}

const printCards = () => {
  isLoading.value = true;
  openPrintWindow(selection.value, vereinName.value, verbandName.value, []).then(() => {
    isLoading.value = false;
  });
}

const printSingleCards = () => {
  isLoading.value = true;
  openSingleCardsPrintWindow(selection.value, vereinName.value, verbandName.value, []).then(() => {
    isLoading.value = false;
  });
}

const reloadClubMembers = (clubId) => {
  if (clubId !== currentClubId.value) {
    isLoading.value = true;
    clubStore.setCurrentClubId(clubId);
    clubStore.setCurrentClubName(vereinName.value);
    clubStore.loadClubMembers(clubId, false).then(() => {
      isLoading.value = false;
    });
  }
}

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}
const exportCSV = () => {
  dt.value.exportCSV({selectionOnly: selection.value.length > 0});
}

watch(
  () => route.query.clubId,
  async newClubId => {
    reloadClubMembers(newClubId)
  }, {immediate: true}
)

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
  new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
  })
})
</script>
