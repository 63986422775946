<template>
  <div class="col-12">
    <h2>{{ t('club.members.import.title') }}</h2>

    <DataTable id="uploadTable" :value="previousUploads" :loading="isLoading"
               :sort-field="sortColumn" :sort-order="-1" @sort="onSort"
               data-key="documentName"
               scrollable
               :paginator="previousUploads.length > 20" :rows="50" :rowsPerPageOptions="[20, 50, 100, 150]"
               paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
               :currentPageReportTemplate="`{first}` + ' ' + t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
               stripedRows>

      <template #empty>{{ t('club.members.import.notFound') }}</template>
      <template #paginatorfirstpagelinkicon>
        <i class="bi bi-arrow-bar-left"/>
      </template>
      <template #paginatorprevpagelinkicon>
        <i class="bi bi-arrow-left"/>
      </template>
      <template #paginatornextpagelinkicon>
        <i class="bi bi-arrow-right"/>
      </template>
      <template #paginatorlastpagelinkicon>
        <i class="bi bi-arrow-bar-right"/>
      </template>

      <Column v-for="col in columns" :key="col.field" :field="col.field"
              :header="col.header"
              :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
              :class="col.border ? 'border-start' : null"
              :hidden="!col.visible">
        <template #body="{data}">
          <Button v-if="col.field === 'status' && data[col.field] === 'ERROR'"
                  :title="t('club.members.import.error')" :label="formatEntry(col.type, data[col.field])"
                  unstyled class="btn btn-link p-0"
                  @click="showErrorDialog(data)"/>
          <span v-else-if="col.field === 'fullName'">{{ getFullName(data) }}</span>
          <span v-else>{{ formatEntry(col.type, data[col.field]) }}</span>
        </template>
      </Column>

    </DataTable>

    <div class="row w-100 flex-center mb-3 mt-3">
      <Button :title="t('club.members.import.new')" :label="t('club.members.import.new')" icon="bi bi-file-earmark-arrow-up" outlined class="me-1 width-auto" severity="primary" @click="openDialog"/>
      <Button :title="t('club.members.downloadTemplate')" :label="t('club.members.downloadTemplate')" icon="bi bi-file-earmark-arrow-down" outlined class="me-1 width-auto" severity="secondary" @click="downloadTemplate"/>
    </div>
  </div>

  <Dialog v-model:visible="visibleUploadDialog" modal
          :header="t('club.members.import.title')"
          :style="{width: '35rem'}">
    <div class="mb-3 mt-3">
      <label for="fileUpload" class="form-label">{{ t('club.members.import.new') }}</label>
      <input type="file" id="fileUpload" ref="fileUpload" class="form-control mt-2" accept=".xls,.xlsx,.csv" @change="handleFileUpload">
    </div>

    <template #footer>
      <div class="w-100 d-flex justify-content-between pt-3">
        <Button :title="t('club.members.import.upload.label')" :label="t('club.members.import.upload.label')"
                unstyled class="btn btn-outline-primary"
                :disabled="!selectedFile || isSubmitted"
                @click="uploadFile"/>
        <Button :title="t('cancel')" :label="t('cancel')" unstyled class="btn btn-outline-secondary" @click="closeDialog"/>
      </div>
    </template>
  </Dialog>

  <Dialog v-model:visible="visibleErrorDialog" modal
          :header="t('club.members.import.error')"
          :style="{width: '35rem'}" @hide="closeErrorDialog">
    <div class="form-row">
      <label class="form-label">{{ t('club.members.import.fileName') }}</label>
      <p>{{ errorDoc.fileName }}</p>
    </div>

    <div class="form-row">
      <label class="form-label">{{ t('club.members.import.uploadDate') }}</label>
      <p>{{ formatDate(errorDoc.createdDate, 'DD.MM.YYYY HH:mm') }}</p>
    </div>

    <div class="form-row">
      <label class="form-label">{{ t('club.members.import.uploadedBy') }}</label>
      <p>{{ errorDoc.memberFirstName }} {{ errorDoc.memberSurname }}</p>
    </div>

    <div class="form-row">
      <label class="form-label">{{ t('club.members.import.error') }}</label>
      <p>{{ errorDoc.message }}</p>
    </div>

    <template #footer>
      <div class="w-100 d-flex justify-content-between pt-3">
        <Button :title="t('close')" :label="t('close')" unstyled class="btn btn-outline-secondary" @click="closeErrorDialog"/>
      </div>
    </template>
  </Dialog>

</template>

<script setup>
import {useI18n} from "vue-i18n";
import {computed, onMounted, ref, useTemplateRef, watch} from "vue";
import moment from "moment";
import ApiClient from "@/api/ApiClient";
import {useClubStore} from "@/store/modules/club/club";
import {useRoute} from "vue-router";
import {useMessagesStore} from "@/store/modules/messages/messages";

const {t, locale} = useI18n();
const route = useRoute();

const clubStore = useClubStore();
const messageStore = useMessagesStore();

const isLoading = ref(false);
const visibleUploadDialog = ref(false);
const selectedFile = ref(null);
const fileUpload = useTemplateRef('fileUpload');

const isSubmitted = ref(false);
const showSuccess = ref(false);
const showError = ref(false);

const visibleErrorDialog = ref(false);
const errorDoc = ref(null);

const currentClubId = computed(() => {
  return clubStore.getCurrentClubId;
});

const openDialog = () => {
  visibleUploadDialog.value = true;
};

const closeDialog = () => {
  selectedFile.value = null;
  if (fileUpload?.value?.value) {
    fileUpload.value.value = null;
  }
  visibleUploadDialog.value = false;
};

const showErrorDialog = (data) => {
  errorDoc.value = data;
  visibleErrorDialog.value = true;
};

const closeErrorDialog = () => {
  errorDoc.value = null;
  visibleErrorDialog.value = false;
};

const handleFileUpload = (event) => {
  const files = event.target.files || event.dataTransfer.files;
  if (!files.length) {
    return;
  }
  selectedFile.value = files[0];
};

const uploadFile = async () => {
  isSubmitted.value = true;
  showSuccess.value = false;
  showError.value = false;

  let formData = new FormData();
  formData.append("file", selectedFile.value);
  formData.append("clubNo", currentClubId.value);

  const requestPath = "/api/club/importData";
  ApiClient.fileUploadRequestWithAuthorization(requestPath, formData).then(response => {
    if (response.status === 200) {
      showSuccess.value = true;
      visibleUploadDialog.value = false;
      loadImportData(currentClubId.value);
      if (response.data?.statusCodeValue < 400) {
        clubStore.loadClubListMembers(currentClubId.value, false, true, true, locale.value);
        messageStore.addDefaultMessage('info', t('club.members.import.upload.uploaded'), t('club.members.import.upload.success', {file: selectedFile.value.name}));
      } else {
        messageStore.addDefaultMessage('error', t('club.members.import.upload.uploaded'), t('club.members.import.upload.failure', {file: selectedFile.value.name}));
      }
      closeDialog();
    } else {
      showError.value = true;
      messageStore.addDefaultMessage('error', t('club.members.import.upload.failed'), t('club.members.import.upload.error', {file: selectedFile.value.name}));
    }
  }).catch(error => {
    console.error(error);
    messageStore.addDefaultMessage('error', t('club.members.import.upload.failed'), t('club.members.import.upload.error', {file: selectedFile.value.name}));
  }).finally(() => {
    selectedFile.value = null;
    isSubmitted.value = false;
    if (fileUpload?.value?.value) {
      fileUpload.value.value = null;
    }
  });
};

const previousUploads = computed(() => {
  return clubStore.getImportData;
});
const columns = [
  {field: 'fileName', header: t('club.members.import.fileName'), sortable: true, filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'createdDate', header: t('club.members.import.uploadDate'), sortable: true, filterable: false, exportable: true, visible: true, type: 'Date'},
  {field: 'fullName', header: t('club.members.import.uploadedBy'), sortable: true, filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'status', header: t('club.members.import.status'), sortable: true, filterable: false, exportable: true, visible: true, type: 'Status'},
];
const sortColumn = ref('createdDate');

const getFullName = (data) => {
  return data.memberFirstName + ' ' + data.memberSurname;
};

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value, 'DD.MM.YYYY HH:mm');
    case 'Array':
      return value.join(', ');
    case 'Salutation':
      if (value === 'UNDEFINED') {
        return '';
      }
      return t('club.listMember.salutation' + value);
    case 'Language':
      return t('languages.' + value.toLowerCase());
    case 'Status':
      return t('club.members.import.uploadStatus.' + value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
};

const downloadTemplate = () => {
  const assetDir = '/static/'
  const fileName = locale.value === 'fr' ? 'importation_membres_d_association.xls' : 'import_vereinsmitglieder.xls';

  const dlLink = document.createElement('a');
  dlLink.href = assetDir + fileName;
  dlLink.setAttribute('download', fileName);
  dlLink.click();
};

const loadImportData = (clubId) => {
  isLoading.value = true;
  clubStore.loadImportDataForClub(clubId).then(() => {
    isLoading.value = false;
  });
};

watch(
  () => route.query.clubId,
  async newClubId => {
    loadImportData(newClubId);
  }, {immediate: true}
);

onMounted(() => {
  loadImportData(route.query.clubId);
});
</script>
