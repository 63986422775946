<template>
  <association-layout :title="associationData.status === 200 ? $t('association.profile') + ' ' + associationData.data.Name : $t('association.notFound.title')" :is-loading="false">
    <template #content v-if="associationData.status === 200">

      <div class="row">
        <div class="col">
          <h5 class="mb-3">
            {{ $t('association.lspaClub', {clubName: vereinName}) }}
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <DataTable :value="lspaClubMembers"
                     scrollable scroll-height="60vh"
                     :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                     data-key="memberId" ref="dt"
                     v-model:filters="filters" filter-display="row"
                     :loading="isLoading"
                     :paginator="lspaClubMembers?.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                     csv-separator=";" :export-filename="$t('association.lspaClub', {clubName: vereinName}).replaceAll(' ', '_')" :export-function="exportFunction"
                     row-hover
                     class="border-bottom">

            <template #empty>
              <div class="alert alert-info">{{ $t('noData') }}</div>
            </template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column v-for="col in columns" :key="col.field" :field="col.field"
                    :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                    :header="col.header"
                    :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :exportable="col.exportable"
                    :hidden="!col.visible">
              <template #body="{data}">
                {{ formatEntry(col.type, data[col.field]) }}
              </template>
              <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
                <Select v-model="filterModel.value" @change="filterCallback()" :options="possibleStatus" :placeholder="$t('filter')" :showClear="true" v-if="col.type === 'Status'">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex items-center">
                      <div>{{ $t('lspa.status.' + slotProps.value) }}</div>
                    </div>
                    <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                  </template>
                  <template #option="slotProps">
                    {{ $t('lspa.status.' + slotProps.option) }}
                  </template>
                </Select>
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('table.filter.header', {filter: col.header})" class="form-control input-filter" v-else/>
              </template>
            </Column>

          </DataTable>

          <div class="row w-100 flex-center gap-2 mb-3 mt-3">
            <router-link :to="{name: ASSOC_SHOW_CLUBS.name, query: {associationId: currentAssociationId}}" class="btn btn-outline-secondary width-auto">
              <i class="bi bi-arrow-left me-2"></i> {{ $t('back') }}
            </router-link>
            <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary width-auto">
              <i class="bi bi-filetype-csv"></i>
            </Button>
          </div>
        </div>
      </div>
      <LoaderOverlay v-if="isLoading"/>

      <div class="row">
        <FaqPanel class="m-0 border-0"/>
      </div>
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>

  </association-layout>

  <BootstrapToast/>
  <ConfirmDialog style="width: 25rem;" :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse pt-3'}, content: {class: 'd-flex flex-column border-bottom'}, icon: {class: 'w-auto h-auto flex-shrink-1', style: 'font-size: 3.5rem; line-height: 0'}}"/>
</template>

<script setup>
import BootstrapToast from '@/components/ui/BootstrapToast.vue';
import {FilterMatchMode} from "@primevue/core/api"

import {computed, onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import ApiClient from "@/api/ApiClient";
import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import {useI18n} from "vue-i18n";
import moment from "moment";
import Message from "@/helpers/message";

import Select from 'primevue/select';
import {useClubStore} from "@/store/modules/club/club";
import {useAssociationStore} from "@/store/modules/association/association";
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import Button from "primevue/button";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import ConfirmDialog from "primevue/confirmdialog";
import {ASSOC_SHOW_CLUBS} from "@/router/urls_association";

const route = useRoute()
const clubStore = useClubStore();
const associationStore = useAssociationStore();
const messagesStore = useMessagesStore();
const {t} = useI18n();

const isSelectable = ref(false);
const isLoading = ref(false);
const selection = ref([]);
const dt = ref();

const sortColumn = ref("memberId");
const lspaClubMembers = ref([]);
const lspaClubId = ref();
const associationId = ref();

const associationData = computed(() => clubStore.getClubData);
const currentClubData = computed(() => associationStore.getCurrentClubData);

const possibleStatus = ref([ 'ACTIVE', 'EXPIRED', 'NO_ACTIVE_MEMBERSHIP' ])
const filters = ref({
  memberId: {value: null, matchMode: FilterMatchMode.CONTAINS},
  lastName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  firstName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  birthday: {value: null, matchMode: FilterMatchMode.CONTAINS},
  issuingClubName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  latestYearValid: {value: null, matchMode: FilterMatchMode.CONTAINS},
  status: {value: null, matchMode: FilterMatchMode.EQUALS},
});
const columns = [
  {field: 'memberId', header: t('personalData.person.stvNumber'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'firstName', header: t('personalData.person.firstName'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'lastName', header: t('personalData.person.surname'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'birthday', header: t('personalData.person.birthday'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Date'},
  {field: 'issuingClubName', header: t('lspa.clubName'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
  {field: 'sportId', header: t('lspa.sportId'), filterable: false, sortable: false, exportable: true, visible: true, type: 'Sportart'},
  {field: 'latestYearValid', header: t('lspa.validYear'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Number'},
  {field: 'status', header: t('lspa.status.status'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Status'},
];

const vereinName = computed(() => {
  return currentClubData.value.data.Name;
});

const currentAssociationId = computed(() => {
  return associationData.value.data.No;
});

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return [ ...new Set(Array.prototype.map.call(value, s => s)) ].join('\n');
    case 'Salutation':
      if (value === 'MS') {
        return t('club.listMember.salutationMS');
      } else if (value === 'MR') {
        return t('club.listMember.salutationMR');
      }
      return value;
    case 'Language':
      if (value === 'de' || value === 'DE') {
        return t('languages.de');
      } else if (value === 'fr' || value === 'FR') {
        return t('languages.fr');
      }
      return value;
    case 'Sportart':
      return t("sport." + value);
    case 'Status':
      return t('lspa.status.' + value);
    default:
      return value;
  }
}

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}

async function reloadClubMembers(clubId, associationId, showLoadingIndicator) {
  if (showLoadingIndicator) {
    isLoading.value = true;
  }
  const request = "/api/lspa/members?lspaClubId=" + clubId + (associationId ? '&associationId=' + associationId : '');

  const response = await ApiClient.getRequest(request);

  if (response && response.status === 200) {
    lspaClubMembers.value = response.data;
  } else if (response.status === 403) {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.access_denied_header'), "", t('errorMessages.access_denied_text')));
  } else if (response.data) {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", response.data.error.message));
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
  }
  isLoading.value = false;
}

const onSort = (event) => {
  sortColumn.value = event.sortField;
}

const exportFunction = (entry) => {
  switch (entry.field) {
    case 'birthday':
      return formatEntry('Date', entry.data);
    case 'latestYearValid':
      return formatEntry('Number', entry.data);
    case 'status':
      return formatEntry('Status', entry.data);
    case 'sportId':
      return formatEntry('Sportart', entry.data);
    default:
      return String(entry.data)
  }
}

const exportCSV = () => {
  dt.value.exportCSV();
};

watch(
  () => [ route.query.clubId, route.query.associationId ],
  async ([ newClubId, newAssociationId ]) => {
    lspaClubId.value = newClubId;
    associationId.value = newAssociationId;
    await reloadClubMembers(newClubId, newAssociationId, true);
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
  isLoading.value = true;
  associationStore.loadClubData(route.query.associationId, route.query.clubId).then(() => {
    isLoading.value = false;
  });
});
</script>
