<template>
  <Dialog modal block-scroll :header="props.dialogHeader" :style="{width: '30rem'}" @hide="resetAddressRelation">
    <div class="form-row mb-3">
      <label for="clubName" class="form-label">{{ t('club.name') }}</label>
      <Select id="clubName" v-model="addressRelation.Relation_to_No"
              :options="clubs" option-value="No" option-label="Name"
              filter
              :placeholder="t('club.name')"
              class="w-100"
              :disabled="!isEmpty(associationMember.entryNo)"
              @change="loadClubMembers"
              @update:modelValue="analyzeAddressRelationData"/>
    </div>
    <div class="form-row mb-3">
      <label for="contactNo" class="form-label">{{ t('institutes.functions.selectMember') }}</label>
      <Select id="contactNo" v-model="addressRelation.Contact_No"
              :options="clubMembers" option-value="memberId" :option-label="getMemberName"
              filter
              :placeholder="t('institutes.functions.selectMember')"
              class="w-100"
              :loading="membersLoading"
              :disabled="membersLoading || !isEmpty(associationMember.entryNo) || isEmpty(addressRelation.Relation_to_No)"
              @update:modelValue="analyzeAddressRelationData"/>
    </div>
    <div class="form-row mb-3">
      <label for="functionCode" class="form-label">{{ t('association.functionsAndActivities.add.selectFunction') }}</label>
      <Select id="functionCode" v-model="addressRelation.Function_Code"
              :options="functions" option-value="Code"
              :option-label="$i18n.locale === 'fr' ? 'DescriptionFR' : 'Description'"
              filter
              :placeholder="t('association.functionsAndActivities.add.selectFunction')"
              :disabled="isEmpty(addressRelation.Contact_No)"
              @update:modelValue="analyzeAddressRelationData"
              class="w-100"/>
    </div>
    <div class="form-row mb-3">
      <label for="byDate" class="form-label">{{ t('from') }}</label>
      <DatePicker id="byDate"
                  v-model="addressRelation.By_Date"
                  :model-value="addressRelation.By_Date ? new Date(addressRelation.By_Date) : null"
                  date-format="dd.mm.yy"
                  showIcon :show-button-bar="true"
                  class="w-100"
                  @update:modelValue="analyzeAddressRelationData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!dataValid.By_Date">{{ t('association.functionsAndActivities.errors.byDateInvalid') }}</PrimeMessage>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="dateAfter(addressRelation.By_Date, addressRelation.To_Date)">{{ t('association.functionsAndActivities.errors.byDateAfterToDate') }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label for="toDate" class="form-label">{{ t('to') }}</label>
      <DatePicker id="toDate"
                  v-model="addressRelation.To_Date"
                  :model-value="addressRelation.To_Date ? new Date(addressRelation.To_Date) : null"
                  date-format="dd.mm.yy"
                  showIcon :show-button-bar="true"
                  class="w-100"
                  @update:modelValue="analyzeAddressRelationData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!dataValid.To_Date">{{ t('association.functionsAndActivities.errors.toDateInvalid') }}</PrimeMessage>
    </div>

    <div class="d-flex justify-content-between">
      <Button :title="t('association.functionsAndActivities.add.save')" :label="t('association.functionsAndActivities.add.save')"
              unstyled class="btn btn-outline-primary"
              :disabled="!allDataValid"
              @click="submitAddressRelation"/>
      <Button :title="t('cancel')" :label="t('cancel')"
              unstyled class="btn btn-outline-secondary"
              @click="$emit('cancel')"/>
    </div>
  </Dialog>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {allTrue, dateAfter, dateValid, isEmpty, isNotEmptyWithinMaxLength} from "@/helpers/validation";
import {useAssociationStore} from "@/store/modules/association/association";
import {useI18n} from "vue-i18n";
import {useMessagesStore} from "@/store/modules/messages/messages";

const associationStore = useAssociationStore();
const messageStore = useMessagesStore();
const {t, locale} = useI18n();

const emit = defineEmits([ 'memberActivitiesChanged', 'error', 'cancel' ]);

const props = defineProps({
  dialogHeader: {
    type: String,
    required: true,
  },
  associationMember: {
    type: Object,
    required: true,
  },
  functions: {
    type: Array,
    required: true,
  },
  associationId: {
    type: String,
    required: true,
  },
});

const clubMembers = ref([]);
const membersLoading = ref(false);
const addressRelation = ref({});

const clubs = computed(() => {
  return associationStore.getAllClubs;
});

const resetAddressRelation = () => {
  addressRelation.value = {};
};

const loadClubMembers = async () => {
  if (addressRelation.value.Relation_to_No) {
    membersLoading.value = true;
    associationStore.loadClubListMembers(props.associationId, addressRelation.value.Relation_to_No, false, false, false, locale.value).then(() => {
      clubMembers.value = associationStore.getClubMembers.toSorted((a, b) => a.surname.localeCompare(b.surname));
      membersLoading.value = false;
    });
  }
};

const loadPropertyValues = () => {
  if (props.associationMember.entryNo) {
    addressRelation.value = {
      Entry_No: props.associationMember.entryNo,
      Contact_No: props.associationMember.stvMember?.memberId,
      Function_Code: props.associationMember.function?.Code,
      Relation_to_No: props.associationMember.clubNo,
      By_Date: dateValid(props.associationMember.byDate) ? new Date(props.associationMember.byDate) : null,
      To_Date: dateValid(props.associationMember.toDate) ? new Date(props.associationMember.toDate) : null,
    };
  } else {
    addressRelation.value = {};
  }
};

watch(() => props.associationMember.entryNo, loadPropertyValues, {immediate: true});
watch(() => addressRelation.value.Relation_to_No, loadClubMembers, {immediate: true});

const isSubmitted = ref(false);
const dataValid = ref({
  Entry_No: true,
  Contact_No: false,
  Function_Code: false,
  Relation_to_No: false,
  By_Date: true,
  To_Date: true,
});

const allDataValid = computed(() => {
  return allTrue(dataValid.value);
});

const getMemberName = (member) => {
  return member.firstName + ' ' + member.surname;
};

const analyzeAddressRelationData = () => {
  checkProperties(addressRelation);
  isSubmitted.value = false;
};

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'Contact_No') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'Function_Code') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'Relation_to_No') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'By_Date') {
      dataValid.value[key] = (!obj.value[key]) || dateValid(obj.value[key]);
    } else if (key === 'To_Date') {
      dataValid.value[key] = (!obj.value[key]) || dateValid(obj.value[key]);
    }
  }
};

const submitAddressRelation = () => {
  checkProperties(addressRelation);
  if (allDataValid.value) {
    console.log('submitted data: ', addressRelation.value);
    isSubmitted.value = true;
    associationStore.saveAddressRelation(props.associationId, addressRelation.value).then(async response => {
      if (response?.status >= 200 && response?.status < 300) {
        messageStore.addDefaultMessage('info', t('association.functionsAndActivities.add.title'), t('association.functionsAndActivities.add.success'));
        emit('memberActivitiesChanged');
      } else {
        let combinedMessage = '';
        if (response.response?.data?.errors) {
          response.response?.data.errors.forEach(e => {
            combinedMessage += (t(e) + '\r\n');
          });
        } else if (response.response?.data) {
          combinedMessage = response.response.data;
        }
        messageStore.addDefaultMessage('error', t('association.functionsAndActivities.add.title'), t(combinedMessage));
      }
    }).catch(error => {
      messageStore.addDefaultMessage('error', t('institutes.functions.save.error'), error);
      emit('error');
    }).finally(() => {
      isSubmitted.value = false;
    });
  }
};

onMounted(() => {
  loadClubMembers();
});
</script>
