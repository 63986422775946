import axios from "axios";
import logger from "@/logger/index.js";
import Message from "@/helpers/message.js";
import i18n from "@/i18n.js";

import {useLoginStore} from "@/store/modules/login/login.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";

const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
}

const APPLE_PASS_RESPONSE_TYPE = "application/octet-stream";

const config = {
  baseURL: window.VUE_APP_BACKEND_BASE_URL,
  headers: headers,
  withCredentials: true
}

// eslint-disable-next-line no-unused-vars
const {global: {locale, t},} = i18n;

const client = axios.create(config);
client.interceptors.request.use((conf) => {
  return conf
})

export default {
  // POST
  async postRequestWithAuthorization(path, body) {
    const loginStore = useLoginStore()
    config.headers["Authorization"] = "Bearer " + loginStore.getAccessToken;
    return this.executePostRequest(path, body);
  },
  async postRequestWithoutAuthorization(path, body) {
    delete config.headers["Authorization"];
    return this.executePostRequest(path, body);
  },
  async fileUploadRequestWithAuthorization(path, body) {
    config.headers["Content-Type"] = "multipart/form-data";
    return this.postRequestWithAuthorization(path, body);
  },
  async executePostRequest(path, body) {
    return client.post(path, body, config).then(response => {
      logger.debug(response);
      if (response.status !== 200) {
        logger.debug("Response fehlerhaft");
      }
      return response;
    }).catch(error => {
      logger.error(error);
      return error.response;
    });
  },

  // PUT
  async putRequest(path, body) {
    const loginStore = useLoginStore()
    config.headers["Authorization"] = "Bearer " + loginStore.getAccessToken;
    return this.executePutRequest(path, body);
  },
  async executePutRequest(path, body) {
    return client.put(path, body, config).then(response => {
      logger.debug(response);
      if (response.status !== 200) {
        logger.debug('Response fehlerhaft');
      }
      return response;
    }).catch(error => {
      logger.error(error);
      return error;
    });
  },

  // GET
  async getApplePass(path) {
    config.headers["Accept"] = APPLE_PASS_RESPONSE_TYPE;
    let response = this.getRequest(path);
    config.headers["Accept"] = "application/json";
    return response;

  },
  async getRequest(path) {
    const loginStore = useLoginStore()
    config.headers["Authorization"] = "Bearer " + loginStore.getAccessToken;
    return this.executeGetRequest(path);
  },
  async getRequestWithoutAuthorization(path) {
    delete config.headers["Authorization"];
    return this.executeGetRequest(path);
  },
  async executeGetRequest(path) {
    const messageStore = useMessagesStore()
    messageStore.resetErrors('BACKEND_API')

    return client.get(path, config).then(response => {
      if (response.status !== 200) {
        logger.debug('Response fehlerhaft');
        logger.debug(response);
      }
      return response;
    }).catch(error => {
      let message = null;
      logger.warn(error);
      if (error.response !== undefined && error.response !== null && error.response.data !== null) {
        if (error.response.status === 403) {
          logger.error("Unable to retrieve data: " + error.response.data.error);
          if (error.response.data.error === 'Forbidden') {
            message = new Message('error', false, true, t("errorMessages.access_denied_header"), '', t("errorMessages.access_denied_text"), true, 'BACKEND_API');
            messageStore.addError(message);
            return null;
          }
        }
        return error.response;
      }

      if (error.messages !== null) {
        logger.error("Unable to retrieve data: " + error.message);
        message = new Message('error', false, true, error.message, '', t("errorMessages.network_error_text"), true, 'BACKEND_API');
        messageStore.addError(message);
      } else {
        logger.error("Generic error: " + error);
        message = new Message('error', false, true, t("errorMessages.error"), '', t("errorMessages.generic_error"), true, 'BACKEND_API')
        messageStore.addError(message);
      }
      return null;
    });
  },

  // DELETE
  async deleteRequest(path) {
    const loginStore = useLoginStore()
    config.headers["Authorization"] = "Bearer " + loginStore.getAccessToken;
    return this.executeDeleteRequest(path);
  },
  async executeDeleteRequest(path) {
    return client.delete(path, config).then(response => {
      if (!(response.status >= 200 && response.status < 300)) {
        logger.error('Response fehlerhaft');
      }
      return response;
    }).catch(error => {
      logger.error(error);
      return error.response;
    });
  },
};
