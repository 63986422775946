<template>
  <div class="row">
    <div class="col">
      <h5 class="mb-3">{{ $t('association.club.functions.title') }}</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <DataTable :value="associationClubAddressRelations"
                 scrollable scroll-height="60vh"
                 :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                 data-key="entryNo"
                 v-model:filters="filters" filter-display="row"
                 ref="dt" :export-filename="$t('association.club.functions.title').replaceAll(' ', '_')" :export-function="csvExportTransformationFunction"
                 :loading="isLoading"
                 :paginator="associationClubAddressRelations.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                 csv-separator=";"
                 row-hover
                 class="border-bottom">

        <Column>
          <template #body="{data}">
            <div class="btn-group btn-group-sm pe-2">
              <Button :title="t('association.functionsAndActivities.edit.title')" :aria-label="t('association.functionsAndActivities.edit.title')"
                      :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm"
                      unstyled class="btn btn-outline-secondary" @click="showEditAddressRelationModal(data)">
                <i class="bi bi-pencil"></i>
              </Button>
              <Button :title="t('association.functionsAndActivities.delete.title')" :aria-label="t('association.functionsAndActivities.delete.title')"
                      unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data.entryNo)">
                <i class="bi bi-trash"></i>
              </Button>
            </div>
          </template>
        </Column>

        <Column v-for="col in columns" :key="col.field" :field="col.field"
                :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header="col.header"
                :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                :exportable="col.exportable"
                :hidden="!col.visible">
          <template #body="{data}">
            {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
          </template>
          <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <div class="row w-100 flex-center gap-2 mb-3 mt-3">
    <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
      <i class="bi bi-filetype-csv"></i>
    </Button>
    <Button :title="t('club.functionsAndActivities.add.title')" :label="t('club.functionsAndActivities.add.title')"
            :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm me-2"
            unstyled class="btn btn-outline-secondary width-auto" icon="bi bi-plus-circle me-2"
            @click="showEditAddressRelationModal"/>
  </div>

  <AssociationMemberActivityModal v-model:visible="visibleAddressRelationModal"
                                  :association-member="currentAssociationMember"
                                  :association-id="props.associationId"
                                  :functions="currentClubFuntions"
                                  :dialog-header="currentAssociationMember.entryNo ? t('club.functionsAndActivities.edit.title') : t('club.functionsAndActivities.add.title')"
                                  @cancel="visibleAddressRelationModal = false"
                                  @hide="resetAddressRelationData"
                                  @member-activities-changed="reloadAssociationClubActivities"
                                  @error="showFailure = true"/>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import {onBeforeMount, ref, watch} from "vue";
import {FilterMatchMode} from "@primevue/core/api";
import {useI18n} from "vue-i18n";
import moment from "moment";
import Button from "primevue/button";
import {useAssociationStore} from "@/store/modules/association/association";
import AssociationMemberActivityModal from "@/components/activity/AssociationMemberActivityModal.vue";
import {useConfirm} from "primevue/useconfirm";
import {useMessagesStore} from "@/store/modules/messages/messages";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import ConfirmDialog from "primevue/confirmdialog";

const {t, locale} = useI18n();
const confirm = useConfirm();

const associationStore = useAssociationStore();
const messageStore = useMessagesStore();

const props = defineProps({
  associationId: String,
});

const isLoading = ref(false);
const associationClubAddressRelations = ref([]);
const isModalLoading = ref(false);
const visibleAddressRelationModal = ref(false);
const currentAssociationMember = ref({});
const currentClubFuntions = ref([]);
const showSuccess = ref(false);
const showFailure = ref(false);

const dt = ref();
const sortColumn = ref('function.code');
const filters = ref({
  'clubNo': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'clubName': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.Code': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.Description': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.DescriptionFR': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.surname': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.firstName': {value: null, matchMode: FilterMatchMode.CONTAINS},
});
const columns = [
  {field: 'clubNo', header: t('club.nr'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'clubName', header: t('club.name'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'function.Code', header: t('club.functionsAndActivities.list.code'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'function.Description', header: t('club.functionsAndActivities.list.function'), exportable: locale.value === 'de', visible: locale.value === 'de', sortable: true, filterable: true, type: 'String'},
  {field: 'function.DescriptionFR', header: t('club.functionsAndActivities.list.function'), exportable: locale.value === 'fr', visible: locale.value === 'fr', sortable: true, filterable: true, type: 'String'},
  {field: 'byDate', header: t('from'), exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'toDate', header: t('to'), exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'stvMember.memberId', header: t('club.member.stvNumber'), exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'stvMember.salutation', header: t('club.member.salutation'), exportable: true, visible: false, sortable: true, type: 'Salutation'},
  {field: 'stvMember.surname', header: t('personalData.person.surname'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'stvMember.firstName', header: t('personalData.person.firstName'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'stvMember.birthday', header: t('personalData.person.birthday'), exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'stvMember.address', header: t('club.member.address'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.postCode', header: t('club.member.postCode'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.city', header: t('club.member.city'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.emailPrivate', header: t('club.member.emailPrivate'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.emailAlternative', header: t('club.member.emailAlternative'), filterable: true, exportable: true, visible: false, type: 'String'},
  {field: 'stvMember.emailVerein', header: t('club.member.emailVerein'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.phoneMobile', header: t('club.member.phoneMobile'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.phonePrivate', header: t('club.member.phonePrivate'), exportable: true, visible: false, sortable: true, type: 'String'},
];

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => a[b], obj);
};

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    case 'byDate':
    case 'toDate':
    case 'stvMember.birthday':
      return formatEntry('Date', record.data);
    case 'stvMember.salutation':
      return formatEntry('Salutation', record.data);
    default:
      return String(record.data)
  }
};

const exportCSV = () => {
  dt.value.exportCSV();
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      return t('club.member.salutation' + value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const showEditAddressRelationModal = async (data) => {
  isModalLoading.value = true;
  currentAssociationMember.value = await data;
  await loadFunctionsAndMembersAndShowModal();
};

const resetAddressRelationData = () => {
  currentAssociationMember.value = {};
};

const loadFunctionsAndMembersAndShowModal = async () => {
  await associationStore.loadFunctions(props.associationId).then(() => {
    currentClubFuntions.value = associationStore.getClubFunctions.data;
  });

  visibleAddressRelationModal.value = true;
  isModalLoading.value = false;
};

const confirmDelete = (event, entryNo) => {
  confirm.require({
    target: event.currentTarget,
    header: t('association.functionsAndActivities.delete.title'),
    message: t('association.functionsAndActivities.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      deleteAddressRelation(entryNo);
      visibleAddressRelationModal.value = false;
    },
  });
};

const deleteAddressRelation = async (entryNo) => {
  associationStore.deleteClubAddressRelation(props.associationId, entryNo).then(r => {
    if (r.status >= 200 && r.status < 300) {
      showSuccess.value = true;
      messageStore.addDefaultMessage('info', t('association.functionsAndActivities.delete.title'), t('association.functionsAndActivities.delete.success'));
    } else {
      showFailure.value = true;
      messageStore.addDefaultMessage('error', t('association.functionsAndActivities.delete.title'), t(r.data));
    }
  }).catch(e => {
    showFailure.value = true
    console.log(e);
    messageStore.addDefaultMessage('error', t('association.functionsAndActivities.delete.title'), t('club.functionsAndActivities.delete.genericError'));
  }).finally(async () => {
    await loadAssociationClubActivities(props.associationId);
  });
};

const reloadAssociationClubActivities = async () => {
  visibleAddressRelationModal.value = false;
  await loadAssociationClubActivities(props.associationId);
};

const loadAssociationClubActivities = async (associationId) => {
  associationStore.loadListFunctionMembers(associationId).then(() => {
    associationClubAddressRelations.value = associationStore.getListFunctionMembers;
    isLoading.value = false;
  });
};

watch(props, () => {
  loadAssociationClubActivities(props.associationId);
});

onBeforeMount(() => {
  isLoading.value = true;
  loadAssociationClubActivities(props.associationId);
});
</script>
