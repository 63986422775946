<template>
<association-layout :title="$t('association.etat.title')" :is-loading="isLoading">
  <template #content>
    <div class="row">
      <div class="col-12">
        <h5>{{ $t('association.etat.subtitle')}}</h5>
        <DataTable :value="clubEtats"
                   scrollable scroll-height="60vh"
                   :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                   data-key="clubNo"
                   v-model:filters="filters" filter-display="row"
                   ref="dt"
                   :loading="isLoading"
                   :paginator="clubEtats.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                   paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                   :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                   csv-separator=";"
                   :export-filename="$t('association.etat.subtitle')"
                   row-hover
                   class="border-bottom">

          <template #empty>
            <div class="alert alert-info">{{ $t('association.etat.notFound') }}</div>
          </template>
          <template #paginatorfirstpagelinkicon>
            <i class="bi bi-arrow-bar-left"/>
          </template>
          <template #paginatorprevpagelinkicon>
            <i class="bi bi-arrow-left"/>
          </template>
          <template #paginatornextpagelinkicon>
            <i class="bi bi-arrow-right"/>
          </template>
          <template #paginatorlastpagelinkicon>
            <i class="bi bi-arrow-bar-right"/>
          </template>

          <Column v-for="col in columns" :key="col.field" :field="col.field"
                  :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                  :header="col.header"
                  :class="col.class"
                  :frozen = "col.frozen"
                  :export-footer="getFooterSum(col.field)"
                  footer-class="footer"
                  :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary ' : 'tableHeader ')"
                  :exportable="col.exportable"
                  :export-header="col.exportHeader || col.header"
                  :hidden="!col.visible">
            <template #body="{data}">
              <div v-if="col.field === 'clubName'">
                <router-link :to="{name: ASSOC_SHOW_SINGLE_CLUB_ETAT.name, query: {associationId: route.query.associationId, clubId: data.clubNo}}"
                             class="table-link">
                  {{data.clubName}}
                </router-link>
              </div>
              <div v-else>
                {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
              </div>
            </template>
            <template #footer="{column}">
              <div >
              {{ getFooterSum(column.props.field) }}
              </div>
            </template>
            <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
            </template>
          </Column>
        </DataTable>
        <div class="row w-100 flex-center mb-3 mt-3">
          <Button :title="$t('export')" :label="$t('export')" @click="exportCSV()" class="datatable-export me-1 width-auto" severity="secondary" outlined>
            <i class="bi bi-filetype-csv"></i>
          </Button>
        </div>
      </div>
    </div>
  </template>

</association-layout>
</template>

<script setup>
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {FilterMatchMode} from "@primevue/core/api";
import apiClient from "@/api/ApiClient";
import {useRoute} from "vue-router";
import {ASSOC_SHOW_SINGLE_CLUB_ETAT} from "@/router/urls_association";
import Button from "primevue/button";

const {t} = useI18n();
const isLoading = ref(false);
const dt = ref(null);

const sortColumn = ref('clubNo');
const onSort = (event) => {
  sortColumn.value = event.sortField;
}

const associationEtat = ref({});

const clubEtats = computed(() => {
  if(associationEtat.value?.clubEtats) {
    return associationEtat.value.clubEtats;
  }
  return [];
})

const route = useRoute();
const columns = [
  {field: 'clubNo', header: t('club.nr'), filterable: true, exportable: true, sortable: true,  visible: true, type: 'String', class: "footer-bold"},
  {field: 'clubName', header: t('club.name'), frozen: true, filterable: true, exportable: true, sortable: true,  visible: true, type: 'String'},
  {field: 'kat1', header: 1, exportHeader: t('association.etat.kat1'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat2', header: 2, exportHeader: t('association.etat.kat2'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat3', header: 3, exportHeader: t('association.etat.kat3'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat4', header: 4, exportHeader: t('association.etat.kat4'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat5', header: 5, exportHeader: t('association.etat.kat5'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat6', header: 6, exportHeader: t('association.etat.kat6'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat7', header: 7, exportHeader: t('association.etat.kat7'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'totalGymnasticAdults', header: t('association.etat.total1to7'), filterable: false, exportable: true, visible: true, type: 'String', class: "sum-column"},
  {field: 'kat8', header: 8, exportHeader: t('association.etat.kat8'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat9', header: 9, exportHeader: t('association.etat.kat9'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'totalGymnasticLicenseOtherFed', header: t('association.etat.total8to9'), filterable: false, exportable: true, visible: true, type: 'String', class: "sum-column"},
  {field: 'kat10', header: 10, exportHeader: t('association.etat.kat10'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat11', header: 11, exportHeader: t('association.etat.kat11'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'totalNonGymnasticAdults', header: t('association.etat.total10to11'), filterable: false, exportable: true, visible: true, type: 'String', class: "sum-column"},
  {field: 'kat12', header: 12, exportHeader: t('association.etat.kat12'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat13', header: 13, exportHeader: t('association.etat.kat13'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat14', header: 14, exportHeader: t('association.etat.kat14'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat15', header: 15, exportHeader: t('association.etat.kat15'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'totalTwelveToFifteen', header: t('association.etat.total12to15'), filterable: false, exportable: true, visible: true, type: 'String', class: "sum-column"},
  {field: 'kat28', header: 28, exportHeader: t('association.etat.kat28'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'kat29', header: 29, exportHeader: t('association.etat.kat29'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'totalMembersPluSport', header: t('association.etat.total28to29'), filterable: false, exportable: true, visible: true, type: 'String', class: "sum-column"},
  {field: 'totalOneToNinePlusTwentyEight', header: t('association.etat.total1to9plus28'), filterable: false, exportable: true, visible: true, type: 'String', class: "sum-column"},
  {field: 'totalTwelveToFifteenPlusTwentyNine', header: t('association.etat.total12to15plus29'), filterable: false, exportable: true, visible: true, type: 'String', class: "sum-column"},
  {field: 'numberOfActiveMembers', header: t('association.etat.totalAll'), filterable: false, exportable: true, visible: true, type: 'String', class: "column-total-all"},
];

const filters = ref({
  clubNo: {value: null, matchMode: FilterMatchMode.CONTAINS},
  clubName: {value: null, matchMode: FilterMatchMode.CONTAINS},
})
const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => (a && a[b] !== undefined ? a[b] : null), obj);

};
const formatEntry = (type, value) => {
  return value;
};

const getFooterSum = (column) => {
  if(column === 'clubNo') {
    return clubEtats.value.length;
  }
  if(column === 'clubName') {
    return " ";
  }
  if(associationEtat.value?.clubEtats) {
    return String(associationEtat.value[column]);
  }
  return "";
}

const exportCSV = () => {
  dt.value.exportCSV({selectionOnly: false})
};


const loadAssocationEtats = async () => {
  isLoading.value = true;

  const result = await apiClient.getRequest("/api/etat/association?associationId=" + route.query.associationId);

  if(result.status === 200) {
    associationEtat.value = result.data;
  }
  isLoading.value = false;
}

onMounted(async () => {
  await loadAssocationEtats();
})

</script>
<style>
.sum-column.sum-column {
  background-color: lightgrey ;
  font-weight: bold;
}

.column-total-all.column-total-all {
  background-color: #c1e4f5;
  font-weight: bold;
}

.footer.footer-bold {
  font-weight: bold;
}

.footer.footer.footer {
  padding: 0.3rem;
  background-color: lightgrey ;
}

</style>
