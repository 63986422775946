<template>
  <club-layout :title="clubData.status === 200 ? $t('club.profile') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="false">
    <template #content v-if="clubData.status === 200">
      <div class="col">
        <tabs value="members" scrollable>
          <tab-list>
            <tab value="members">{{ t('breadcrumb.club.members') }}</tab>
            <tab value="import">{{ t('club.members.import.title') }}</tab>
          </tab-list>

          <tab-panels :pt="{root: {class: 'px-0 pt-4 mb-5'}}">
            <tab-panel value="members">
              <ShowMembersTab/>
            </tab-panel>
            <tab-panel value="import">
              <MembersImportTab/>
            </tab-panel>
          </tab-panels>
        </tabs>
      </div>

      <div class="row">
        <div class="col-12">
          <FaqPanel class="m-0 border-0"/>
        </div>
      </div>

    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </club-layout>

  <ConfirmDialog style="width: 25rem;" :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse pt-3'}, content: {class: 'd-flex flex-column border-bottom'}, icon: {class: 'w-auto h-auto flex-shrink-1', style: 'font-size: 3.5rem; line-height: 0'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import ConfirmDialog from "primevue/confirmdialog";

import {computed} from 'vue'
import {useClubStore} from "@/store/modules/club/club.js";
import ClubLayout from "@/components/club/ClubLayout";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import ShowMembersTab from "@/views/club/tabs/members/ShowMembersTab.vue";
import {useI18n} from "vue-i18n";
import FaqPanel from "@/components/ui/FaqPanel.vue";
import MembersImportTab from "@/views/club/tabs/members/MembersImportTab.vue";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";

const {t} = useI18n();

const clubStore = useClubStore();
const clubData = computed(() => clubStore.getClubData);
</script>
